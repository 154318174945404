import request from "@/utils/request";

// 日语APP真题试卷列表
export function RealPaperList(params) {
    return request({
        url: '/admin/korean_v3/realPaperList',
        method: 'get',
        params
    })
}


// 删除日语APP真题试卷
export function RealPaperDel(data) {
    return request({
        url: '/admin/korean_v3/realPaperDel',
        method: 'delete',
        data
    })
}

// 添加日语APP真题试卷
export function RealPaperAdd(data) {
    return request({
        url: '/admin/korean_v3/realPaperAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP真题试卷
export function RealPaperEdit(data) {
    return request({
        url: '/admin/korean_v3/realPaperEdit',
        method: 'put',
        data
    })
}

// 语法上传COS签名
export function getRealPaperCosToken(params) {
    return request({
        url: '/admin/korean_v3/getRealPaperCosToken',
        method: 'get',
        params
    })
}

// 韩语APP真题试卷等级
export function RealPaperGrade(params) {
    return request({
        url: '/admin/korean_v3/realPaperGrade',
        method: 'get',
        params
    })
}
