<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
         <el-form-item label="标题">
          <el-input
            placeholder="请输入标题"
            v-model="queryForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加真题</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="realPaperList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="标题" prop="title" align="center"></el-table-column>
        <el-table-column label="等级" prop="grade" align="center">
        </el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加真题 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
         <el-form-item el-form-item label="标题" label-width="100px">
            <el-input v-model="addForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="等级" label-width="100px">
          <el-select
            v-model="addForm.grade"
            placeholder="请选择等级"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in gradeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="描述" label-width="100px">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入描述"
              v-model="addForm.desc"
            />
        </el-form-item>

        <el-form-item el-form-item label="内容详情" label-width="100px">
          <quill-editor
              v-model="addForm.content"
              ref="myQuillEditor"
            >
          </quill-editor>
        </el-form-item>
    
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

var COS = require("cos-js-sdk-v5");
import {
  RealPaperList,
  RealPaperDel,
  RealPaperAdd,
  RealPaperEdit,
  getRealPaperCosToken,
  RealPaperGrade
} from "@/api/Korean/realtest_paper.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        title: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      realPaperList: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        title: "",
        desc: "",
        content: "",
        grade: "",
      },
      addtitle: "",
      isAdd: "add",
      gradeArr:[]
    };
  },
  created() {
    this.getRealPaperList();
    this.getRealPaperGrade()
  },
  methods: {
    // 韩语APP真题试卷等级
    getRealPaperGrade() {
      RealPaperGrade().then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.gradeArr=res.data
      });
    },
    //   韩语APP真题试卷列表
    getRealPaperList() {
      RealPaperList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.realPaperList = res.data.data;
      });
    },

    // 删除真题
    async removeTask(id) {
      const result = await this.$confirm("是否要删除该真题?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      RealPaperDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除真题成功");
        this.getRealPaperList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加真题";
      this.addDialog = true;
    },
    // 添加真题
    addNow() {
      if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (this.addForm.grade == "") {
        this.$message.error("请选择等级");
        return;
      } else if (this.addForm.desc == "") {
        this.$message.error("请输入描述");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请输入内容详情");
        return;
      }
      RealPaperAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加真题成功");
        this.addDialog = false;
        this.getRealPaperList();
      });
    },
    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑真题";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
    },

    // 查看pdf
    OpenPdf(src) {
      window.open(src);
    },
    // 点击立即修改
    editReadNow() {
      if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      }else if (this.addForm.grade == "") {
        this.$message.error("请选择等级");
        return;
      } else if (this.addForm.desc == "") {
        this.$message.error("请输入描述");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请输入内容详情");
        return;
      }
      RealPaperEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改真题成功");
        this.addDialog = false;
        this.getRealPaperList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        title: "",
        desc: "",
        content: "",
        grade: "",
      };
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getRealPaperList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getRealPaperList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getRealPaperList();
    },
  },
};
</script>

<style scoped>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
</style>
 